const KEY_ENABLE_SUPER_ANALYTICS_ENDPOINTS =
  "REACT_APP_ENABLE_SUPER_ANALYTICS_ENDPOINTS";
const KEY_MIXPANEL_TOKEN = "REACT_APP_MIXPANEL_TOKEN";
const KEY_REGIONAL_EU_API_BASE_URL = "REACT_APP_REGIONAL_EU_BASE_URL";
const KEY_REGIONAL_US_API_BASE_URL = "REACT_APP_REGIONAL_US_BASE_URL";
const KEY_SENTRY_DATA_SOURCE_NAME = "REACT_APP_SENTRY_DATA_SOURCE_NAME";
const KEY_SENTRY_ENVIRONMENT = "REACT_APP_SENTRY_ENVIRONMENT";
const KEY_STRIPE_PUBLISHABLE_KEY = "REACT_APP_STRIPE_PUBLISHABLE_KEY";
const KEY_SUPER_API_BASE_URL = "REACT_APP_SUPER_BASE_URL";
const KEY_SYSTEM_STATUS_DASHBOARD_EU_URL =
  "REACT_APP_SYSTEM_STATUS_DASHBOARD_EU_URL";
const KEY_SYSTEM_STATUS_DASHBOARD_US_URL =
  "REACT_APP_SYSTEM_STATUS_DASHBOARD_US_URL";

function fetchEnvVar(key: string): string {
  const val = fetchOptionalEnvVar(key);
  if (!val) {
    throw new Error(`missing environment variable: ${key}`);
  }

  return val;
}

function fetchOptionalEnvVar(key: string): string | undefined {
  return process.env[key];
}

function fetchBooleanEnvVar(key: string): boolean {
  return fetchOptionalEnvVar(key)?.toLowerCase() === "true";
}

const env = {
  base_urls: {
    fetch_for_regional_eu: (): string => {
      return fetchEnvVar(KEY_REGIONAL_EU_API_BASE_URL);
    },

    fetch_for_regional_us: (): string => {
      return fetchEnvVar(KEY_REGIONAL_US_API_BASE_URL);
    },

    fetch_for_super: (): string => {
      return fetchEnvVar(KEY_SUPER_API_BASE_URL);
    },
  },

  feature_flags: {
    enable_super_analytics_endpoints: (): boolean => {
      return fetchBooleanEnvVar(KEY_ENABLE_SUPER_ANALYTICS_ENDPOINTS);
    },
  },

  mixpanel: {
    fetch_token: (): string | undefined => {
      return fetchOptionalEnvVar(KEY_MIXPANEL_TOKEN);
    },
  },

  sentry: {
    fetch_data_source_name: (): string | undefined => {
      return fetchOptionalEnvVar(KEY_SENTRY_DATA_SOURCE_NAME);
    },

    fetch_environment: (): string | undefined => {
      return fetchOptionalEnvVar(KEY_SENTRY_ENVIRONMENT);
    },
  },

  stripe: {
    fetch_publishable_key: (): string => {
      return fetchEnvVar(KEY_STRIPE_PUBLISHABLE_KEY);
    },
  },

  system_status: {
    fetch_dashboard_url_for_eu: (): string | undefined => {
      return fetchOptionalEnvVar(KEY_SYSTEM_STATUS_DASHBOARD_EU_URL);
    },

    fetch_dashboard_url_for_us: (): string | undefined => {
      return fetchOptionalEnvVar(KEY_SYSTEM_STATUS_DASHBOARD_US_URL);
    },
  },
};

export default env;
