import { Identifier, PaginationPayload, SortPayload } from "react-admin";

import { DataKind, Region, Resource } from "../../types";
import { env } from "../../utils";
import { apiUtils } from ".";

// We are resorting to building this mapping ourselves since TypeScript
// doesn't natively provide reverse mapping for string enums:
// https://www.typescriptlang.org/docs/handbook/enums.html#reverse-mappings
const resourceMapping = new Map<string, Resource>();
Object.values(Resource).forEach((resource) => {
  resourceMapping.set(resource, resource);
});

const mapToResourceOrError = (input: string): Resource => {
  const resource = resourceMapping.get(input);
  if (resource == null) {
    throw new Error(`${input} is not a recognized Resource`);
  } else {
    return resource;
  }
};

const computeBackendPathFor = (resource: Resource): string => {
  switch (resource) {
    // Super service resources
    case Resource.Account:
      return "accounts";
    case Resource.AccountOverageNotification:
      return "account_overage_notifications";
    case Resource.AdminRoleMembership:
      return "admin_role_memberships";
    case Resource.AdminUser:
      return "admin_users";
    case Resource.ApiKey:
      return "api_keys";
    case Resource.CanceledEnterpriseOwner:
      return "canceled_enterprise_users";
    case Resource.CanceledInAppPurchaseUser:
      return "canceled_in_app_purchase_users";
    case Resource.CanceledStripeOwner:
      return "canceled_stripe_users";
    case Resource.Charge:
      return "charges";
    case Resource.Company:
      return "companies";
    case Resource.EnterprisePlan:
      return "enterprise_plans";
    case Resource.EnterprisePlanInvoice:
      return "enterprise_plan_invoices";
    case Resource.InAppPurchase:
      return "in_app_purchases";
    case Resource.JobInfo:
      return "job_infos";
    case Resource.PayingEnterpriseOwner:
      return "paying_enterprise_users";
    case Resource.PayingInAppPurchaseUser:
      return "paying_in_app_purchase_users";
    case Resource.PayingStripeOwner:
      return "paying_stripe_users";
    case Resource.SsoInfo:
      return "sso_infos";
    case Resource.StripeSubscription:
      return "stripe_subscriptions";
    case Resource.User:
      return "users";

    // Regional resources available only in US
    case Resource.UsAccountUserCount:
      return "account_user_counts";
    case Resource.UsActiveUserCount:
      return "active_user_counts";

    // Region resources that are only ready in the US for now.
    // Please look at the comments in `Resource` enum to understand
    // why the EU counterparts of these aren't available yet
    case Resource.UsAuditLogProject:
      return "audit_logs/projects";

    // Regional resources from both US & EU
    case Resource.EuBoxToken:
    case Resource.UsBoxToken:
      return "box_tokens";
    case Resource.EuConfigVariable:
    case Resource.UsConfigVariable:
      return "config_variables";
    case Resource.EuDemoProjectTemplate:
    case Resource.UsDemoProjectTemplate:
      return "demo_project_templates";
    case Resource.EuDemoProjectUser:
    case Resource.UsDemoProjectUser:
      return "demo_project_users";
    case Resource.EuDropboxToken:
    case Resource.UsDropboxToken:
      return "dropbox_tokens";
    case Resource.EuFeatureFlag:
    case Resource.UsFeatureFlag:
      return "feature_flags";
    case Resource.EuMicrosoftToken:
    case Resource.UsMicrosoftToken:
      return "microsoft_tokens";
    case Resource.EuProject:
    case Resource.UsProject:
      return "projects";
    case Resource.EuProjectsUser:
    case Resource.UsProjectsUser:
      return "projects_users";
    case Resource.EuStressTestingProject:
    case Resource.UsStressTestingProject:
      return "stress_testing/generate_project";
    case Resource.EuSyncError:
    case Resource.UsSyncError:
      return "sync_errors";
  }
};

const computeDataKindFor = (resource: Resource) => {
  switch (resource) {
    case Resource.Account:
      return DataKind.Account;
    case Resource.AccountOverageNotification:
      return DataKind.AccountOverageNotification;
    case Resource.AdminRoleMembership:
      return DataKind.AdminRoleMembership;
    case Resource.AdminUser:
      return DataKind.AdminUser;
    case Resource.ApiKey:
      return DataKind.ApiKey;
    case Resource.CanceledEnterpriseOwner:
      return DataKind.CanceledEnterpriseOwner;
    case Resource.CanceledInAppPurchaseUser:
      return DataKind.CanceledInAppPurchaseUser;
    case Resource.CanceledStripeOwner:
      return DataKind.CanceledStripeOwner;
    case Resource.Charge:
      return DataKind.Charge;
    case Resource.Company:
      return DataKind.Company;
    case Resource.EnterprisePlan:
      return DataKind.EnterprisePlan;
    case Resource.EnterprisePlanInvoice:
      return DataKind.EnterprisePlanInvoice;
    case Resource.InAppPurchase:
      return DataKind.InAppPurchase;
    case Resource.JobInfo:
      return DataKind.JobInfo;
    case Resource.PayingEnterpriseOwner:
      return DataKind.PayingEnterpriseOwner;
    case Resource.PayingInAppPurchaseUser:
      return DataKind.PayingInAppPurchaseUser;
    case Resource.PayingStripeOwner:
      return DataKind.PayingStripeOwner;
    case Resource.SsoInfo:
      return DataKind.SsoInfo;
    case Resource.StripeSubscription:
      return DataKind.StripeSubscription;
    case Resource.User:
      return DataKind.User;

    case Resource.UsAccountUserCount:
      return DataKind.AccountUserCount;
    case Resource.UsActiveUserCount:
      return DataKind.ActiveUserCount;

    case Resource.UsAuditLogProject:
      return DataKind.AuditLogProject;
    case Resource.UsStressTestingProject:
      return DataKind.StressTestingProject;

    case Resource.EuBoxToken:
    case Resource.UsBoxToken:
      return DataKind.BoxToken;

    case Resource.EuConfigVariable:
    case Resource.UsConfigVariable:
      return DataKind.ConfigVariable;

    case Resource.EuDemoProjectTemplate:
    case Resource.UsDemoProjectTemplate:
      return DataKind.DemoProjectTemplate;

    case Resource.EuDemoProjectUser:
    case Resource.UsDemoProjectUser:
      return DataKind.DemoProjectUser;

    case Resource.EuDropboxToken:
    case Resource.UsDropboxToken:
      return DataKind.DropboxToken;

    case Resource.EuFeatureFlag:
    case Resource.UsFeatureFlag:
      return DataKind.FeatureFlag;

    case Resource.EuMicrosoftToken:
    case Resource.UsMicrosoftToken:
      return DataKind.MicrosoftToken;

    case Resource.EuProject:
    case Resource.UsProject:
      return DataKind.Project;

    case Resource.EuProjectsUser:
    case Resource.UsProjectsUser:
      return DataKind.ProjectsUser;

    case Resource.EuSyncError:
    case Resource.UsSyncError:
      return DataKind.SyncError;
  }
};

const computeBackendBaseUrlFor = (resource: Resource): string => {
  switch (resource) {
    case Resource.Account:
    case Resource.AccountOverageNotification:
    case Resource.AdminRoleMembership:
    case Resource.AdminUser:
    case Resource.ApiKey:
    case Resource.CanceledEnterpriseOwner:
    case Resource.CanceledInAppPurchaseUser:
    case Resource.CanceledStripeOwner:
    case Resource.Charge:
    case Resource.Company:
    case Resource.EnterprisePlan:
    case Resource.EnterprisePlanInvoice:
    case Resource.InAppPurchase:
    case Resource.JobInfo:
    case Resource.PayingEnterpriseOwner:
    case Resource.PayingInAppPurchaseUser:
    case Resource.PayingStripeOwner:
    case Resource.SsoInfo:
    case Resource.StripeSubscription:
    case Resource.User:
      return env.base_urls.fetch_for_super();

    case Resource.EuBoxToken:
    case Resource.EuConfigVariable:
    case Resource.EuDemoProjectTemplate:
    case Resource.EuDemoProjectUser:
    case Resource.EuDropboxToken:
    case Resource.EuFeatureFlag:
    case Resource.EuMicrosoftToken:
    case Resource.EuProject:
    case Resource.EuProjectsUser:
    case Resource.EuStressTestingProject:
    case Resource.EuSyncError:
      return env.base_urls.fetch_for_regional_eu();

    case Resource.UsAccountUserCount:
    case Resource.UsActiveUserCount:
    case Resource.UsAuditLogProject:
    case Resource.UsBoxToken:
    case Resource.UsConfigVariable:
    case Resource.UsDemoProjectTemplate:
    case Resource.UsDemoProjectUser:
    case Resource.UsDropboxToken:
    case Resource.UsFeatureFlag:
    case Resource.UsMicrosoftToken:
    case Resource.UsProject:
    case Resource.UsProjectsUser:
    case Resource.UsStressTestingProject:
    case Resource.UsSyncError:
      return env.base_urls.fetch_for_regional_us();
  }
};

const buildBaseUrlFor = (resource: string | Resource): string => {
  let rsrc;
  if (typeof resource === "string") {
    rsrc = mapToResourceOrError(resource);
  } else {
    rsrc = resource;
  }

  return `${computeBackendBaseUrlFor(rsrc)}/${computeBackendPathFor(rsrc)}`;
};

const urlBuilder = {
  // ====================================================================
  // Methods to help build absolute URLs targetting a particular backend
  // ====================================================================

  /**
   * Builds the absolute URL based on the passed in region & relative URL
   */
  regional: (region: Region, relativeUrl: string): string => {
    let baseUrl;
    switch (region) {
      case Region.Eu:
        baseUrl = env.base_urls.fetch_for_regional_eu();
        break;
      case Region.Us:
        baseUrl = env.base_urls.fetch_for_regional_us();
        break;
    }

    return `${baseUrl}/${relativeUrl}`;
  },

  /**
   * Builds the absolute super service URL based on the relative URL
   */
  super: (relativeUrl: string): string => {
    return `${env.base_urls.fetch_for_super()}/${relativeUrl}`;
  },

  // ====================================================================
  // Methods to help build absolute URLs for `DataProvider` methods
  // ====================================================================

  /**
   * Builds the absolute URL to make a POST call that creates a single item
   */
  create: (resource: string): string => {
    return `${buildBaseUrlFor(resource)}`;
  },

  /**
   * Builds the absolute URL to make a PATCH call that updates a single item
   * for the passed in ID
   */
  update: (resource: string, id: Identifier): string => {
    return `${buildBaseUrlFor(resource)}/${id}`;
  },

  /**
   * Builds the absolute URL to make a DELETE call that deletes a single item
   * for the passed in ID
   */
  delete: (resource: string, id: Identifier): string => {
    return `${buildBaseUrlFor(resource)}/${id}`;
  },

  /**
   * Builds the absolute URL to make a GET call that returns a single item
   * for the passed in ID
   */
  getOne: (resource: string, id: Identifier): string => {
    return `${buildBaseUrlFor(resource)}/${id}`;
  },

  /**
   * Builds the absolute URL to make a GET call that returns a list of
   * items based on the passed in IDs
   */
  getMany: (resource: string, ids: Identifier[]): string => {
    const queryParams = apiUtils.queryParametersStringify({ ids });
    return `${buildBaseUrlFor(resource)}?${queryParams}`;
  },

  /**
   * Builds the absolute URL to make a GET call that returns a list of
   * items based on the passed in filters
   */
  getList: (
    resource: string,
    filter: any,
    sort: SortPayload,
    pagination: PaginationPayload
  ): string => {
    const queryParams = apiUtils.queryParametersStringify({
      filters: apiUtils.formatFilters(filter),
      page: pagination.page,
      per_page: pagination.perPage,
      sorts: apiUtils.formatSorts(sort),
    });

    return `${buildBaseUrlFor(resource)}?${queryParams}`;
  },

  /**
   * Builds the absolute URL to make a GET call that returns a list of
   * @resource items associated to the passed in @target with @id
   */
  getManyReferences: (
    resource: string,
    id: Identifier,
    target: string,
    sort: SortPayload,
    pagination: PaginationPayload
  ): string => {
    const fetchResource = mapToResourceOrError(resource);
    const associatedToResource = mapToResourceOrError(target);

    // Compute data kinds for region agnostic checks
    const fetchDataKind = computeDataKindFor(fetchResource);
    const associatedToDataKind = computeDataKindFor(associatedToResource);

    // There are two types of paths, those that reference a
    // single resource and use filters in the query params:
    //
    // charges?filters[0][account_id_eq]=1
    //
    // and those that reference multiple resources hierarchically:
    //
    // users/1/account
    //
    // The former takes advantage of macro-based routes on the BE
    // and allows us to use pagination. The latter is for one-off
    // endpoints that don't need pagination and involve more complex
    // logic that isn't suitable for the macro-based approach.
    // prettier-ignore
    switch (true) {
      case fetchDataKind === DataKind.Account && associatedToDataKind === DataKind.User:
        return `${buildBaseUrlFor(Resource.User)}/${id}/account`;
      case fetchDataKind === DataKind.Account && associatedToDataKind === DataKind.Company:
        return `${buildBaseUrlFor(Resource.Company)}/${id}/accounts`;
      case fetchDataKind === DataKind.AccountOverageNotification && associatedToDataKind === DataKind.Account:
        return `${buildBaseUrlFor(Resource.AccountOverageNotification)}?${apiUtils.queryParametersStringify({
          filters: apiUtils.formatFilters({
            account_id_eq: id,
          }),
          page: pagination.page,
          per_page: pagination.perPage
        })}`;
      case fetchDataKind === DataKind.AccountUserCount && associatedToDataKind === DataKind.Account:
        // Directly using `urlBuilder.super` instead since the resource is still called
        // `Resource.UsAccountUserCount`. This could be made uniform when it is renamed
        // to `Resource.AccountUserCount`
        return env.feature_flags.enable_super_analytics_endpoints() ? urlBuilder.super(`analytics/account_user_counts?${apiUtils.queryParametersStringify({
          filters: apiUtils.formatFilters({
            account_id_eq: id,
          }),
          page: pagination.page,
          per_page: pagination.perPage,
          sorts: apiUtils.formatSorts(sort),
        })}`) : `${computeBackendBaseUrlFor(Resource.UsAccountUserCount)}/accounts/${id}/history?${apiUtils.queryParametersStringify({
          page: pagination.page,
          per_page: pagination.perPage,
          sorts: apiUtils.formatSorts(sort),
        })}`;
      case fetchDataKind === DataKind.AdminRoleMembership && associatedToDataKind === DataKind.AdminUser:
        return `${buildBaseUrlFor(Resource.AdminRoleMembership)}?${apiUtils.queryParametersStringify({
          filters: apiUtils.formatFilters({
            admin_user_id_eq: id,
          }),
          page: pagination.page,
          per_page: pagination.perPage,
          sorts: apiUtils.formatSorts(sort),
        })}`;
      case fetchDataKind === DataKind.Charge && associatedToDataKind === DataKind.Account:
        return `${buildBaseUrlFor(
          Resource.Charge
        )}?${apiUtils.queryParametersStringify({
          filters: apiUtils.formatFilters({
            account_id_eq: id,
          }),
          page: pagination.page,
          per_page: pagination.perPage,
          sorts: apiUtils.formatSorts(sort),
        })}`;
      case fetchDataKind === DataKind.EnterprisePlanInvoice && associatedToDataKind === DataKind.EnterprisePlan:
        return `${buildBaseUrlFor(Resource.EnterprisePlanInvoice)}?${apiUtils.queryParametersStringify({
          filters: apiUtils.formatFilters({
            enterprise_plan_id_eq: id,
          }),
          page: pagination.page,
          per_page: pagination.perPage,
          sorts: apiUtils.formatSorts(sort),
        })}`;
      case fetchDataKind === DataKind.Project && associatedToDataKind === DataKind.Account:
        return `${buildBaseUrlFor(
          fetchResource
        )}?${apiUtils.queryParametersStringify({
          filters: apiUtils.formatFilters({
            account_id_eq: id,
          }),
          page: pagination.page,
          per_page: pagination.perPage,
          sorts: apiUtils.formatSorts(sort),
        })}`;
      case fetchDataKind === DataKind.Project && associatedToDataKind === DataKind.User:
        return `${computeBackendBaseUrlFor(
          fetchResource
        )}/users/${id}/projects_info?${apiUtils.queryParametersStringify({
          page: pagination.page,
          per_page: pagination.perPage,
          sorts: apiUtils.formatSorts(sort),
        })}`;
      case fetchDataKind === DataKind.Project && associatedToDataKind === DataKind.BoxToken:
      case fetchDataKind === DataKind.Project && associatedToDataKind === DataKind.DropboxToken:
      case fetchDataKind === DataKind.Project && associatedToDataKind === DataKind.MicrosoftToken:
      case fetchDataKind === DataKind.Project && associatedToDataKind === DataKind.SyncError:
        return `${buildBaseUrlFor(associatedToResource)}/${id}/projects?${apiUtils.queryParametersStringify({
          page: pagination.page,
          per_page: pagination.perPage,
          sorts: apiUtils.formatSorts(sort),
        })}`;
      case fetchDataKind === DataKind.SyncError && associatedToDataKind === DataKind.BoxToken:
      case fetchDataKind === DataKind.SyncError && associatedToDataKind === DataKind.DropboxToken:
      case fetchDataKind === DataKind.SyncError && associatedToDataKind === DataKind.MicrosoftToken:
        return `${buildBaseUrlFor(associatedToResource)}/${id}/sync_errors?${apiUtils.queryParametersStringify({
          page: pagination.page,
          per_page: pagination.perPage,
        })}`;
      case fetchDataKind === DataKind.User && associatedToDataKind === DataKind.Account:
        return `${buildBaseUrlFor(
          Resource.User
        )}?${apiUtils.queryParametersStringify({
          filters: apiUtils.formatFilters({
            current_user_of_account_id_eq: id,
          }),
          page: pagination.page,
          per_page: pagination.perPage,
          sorts: apiUtils.formatSorts(sort),
        })}`;
      case fetchDataKind === DataKind.User && associatedToDataKind === DataKind.Project:
        return `${buildBaseUrlFor(
          associatedToResource
        )}/${id}/project_users?${apiUtils.queryParametersStringify({
          page: pagination.page,
          per_page: pagination.perPage,
          sorts: apiUtils.formatSorts(sort),
        })}`;
      default:
        throw new Error(`Unexpected combination of resource (${resource}) and target (${target})`);
    }
  },
};

export default urlBuilder;
