/**
 * The purpose of this CustomCreate component is to reduce
 * boilerplate associated with the create pages and minimize
 * the chance of not including critical parts of the page
 * such as authorization.
 */
import {
  CreateBase,
  RedirectionSideEffect,
  useCreateContext,
  ValidateForm,
} from "react-admin";

import { Action, DataKind } from "../../../types";
import {
  Authorize,
  CardForCreateOrEdit,
  CustomSaveButton,
  CustomSimpleFormForCreateOrEdit,
  CustomToolbar,
  TitleRow,
} from "..";

interface CustomCreateProps {
  checkPermissionFor: DataKind;
  children: any;
  // Our default behavior is to route to the show page of the newly
  // created record. However, there may be special circumstances where
  // we want to route somewhere else which is why we expose this prop.
  // A good example of this is needing to route to the projects list
  // page after creating a project on the project scaffolding page.
  redirect?: RedirectionSideEffect;
  transform?: (data: any) => any;
  validate?: ValidateForm;
}

function CreateTitleRow() {
  const { defaultTitle } = useCreateContext();

  if (defaultTitle) {
    return <TitleRow title={defaultTitle} />;
  } else {
    return null;
  }
}

function CustomCreate(props: CustomCreateProps) {
  return (
    <Authorize action={Action.Create} dataKind={props.checkPermissionFor}>
      <CreateBase
        redirect={props.redirect ?? "show"}
        transform={props.transform}
      >
        <CreateTitleRow />
        <CardForCreateOrEdit>
          <CustomSimpleFormForCreateOrEdit
            toolbar={
              <CustomToolbar>
                <CustomSaveButton />
              </CustomToolbar>
            }
            validate={props.validate}
          >
            {props.children}
          </CustomSimpleFormForCreateOrEdit>
        </CardForCreateOrEdit>
      </CreateBase>
    </Authorize>
  );
}

export default CustomCreate;
