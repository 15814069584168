import Card from "@mui/material/Card";
import { FunctionField, NumberField, RecordContextProvider } from "react-admin";
import { useParams } from "react-router-dom";

import {
  AccountUserCountResponse,
  Action,
  DataKind,
  Resource,
} from "../../types";
import {
  Authorize,
  CustomDatagrid,
  CustomDateTimeField,
  CustomReferenceManyField,
  TitleRow,
} from "../custom";

function AccountHistory() {
  const { account_id } = useParams();

  return (
    <Authorize action={Action.Read} dataKind={DataKind.AccountUserCount}>
      <RecordContextProvider value={{ id: account_id }}>
        <TitleRow title="Account History" />
        <CustomReferenceManyField
          displayPagination={true}
          label={false}
          reference={Resource.UsAccountUserCount}
          target={Resource.Account}
        >
          <Card>
            {/*
              We disable row click as there is no associated show page for
              the account user count resource. Navigating to the show page
              for the underlying resource is our current default upon a
              datagrid row click!
            */}
            <CustomDatagrid disableRowClick={true}>
              <CustomDateTimeField label="Date" source="date" />
              <NumberField label="Count" source="active_users" />
              <FunctionField
                label="Users"
                render={(record: AccountUserCountResponse) => {
                  if (record.user_infos) {
                    return record.user_infos
                      .map((i) => i.email)
                      .sort((e1, e2) => e1.localeCompare(e2))
                      .join(", ");
                  } else if (record.emails) {
                    return record.emails.join(", ");
                  } else {
                    return "";
                  }
                }}
                sortable={false}
              />
            </CustomDatagrid>
          </Card>
        </CustomReferenceManyField>
      </RecordContextProvider>
    </Authorize>
  );
}

export default AccountHistory;
