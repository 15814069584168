enum StripePlanExternalId {
  TeamV1 = "team-v1",
  ProV1 = "pro-v1",
  ProMonthlyV1 = "pro-monthly-v1",
  ProMonthlyV2 = "pro-monthly-v2",
  ProMonthlyV3 = "pro-monthly-v3",
  ProMonthlyV4 = "pro-monthly-v4",
  ProMonthlyV5 = "pro-monthly-v5",
  ProYearlyV1 = "pro-yearly-v1",
  ProYearlyV2 = "pro-yearly-v2",
  ProYearlyV3 = "pro-yearly-v3",
  ProYearlyV4 = "pro-yearly-v4",
  ProYearlyV5 = "pro-yearly-v5",
  ProYearlyV6 = "pro-yearly-v6",
  ProYearlyV7 = "pro-yearly-v7",
  BusinessV1 = "business-v1",
  BusinessMonthlyV1 = "business-monthly-v1",
  BusinessMonthlyV2 = "business-monthly-v2",
  BusinessMonthlyV3 = "business-monthly-v3",
  BusinessMonthlyV4 = "business-monthly-v4",
  BusinessMonthlyV5 = "business-monthly-v5",
  BusinessMonthlyV6 = "business-monthly-v6",
  BusinessYearlyV1 = "business-yearly-v1",
  BusinessYearlyV2 = "business-yearly-v2",
  BusinessYearlyV3 = "business-yearly-v3",
  BusinessYearlyV4 = "business-yearly-v4",
  BusinessYearlyV5 = "business-yearly-v5",
  BusinessYearlyV6 = "business-yearly-v6",
  BusinessYearlyV7 = "business-yearly-v7",
  BusinessPlusMonthlyV1 = "business-plus-monthly-v1",
  BusinessPlusMonthlyV2 = "business-plus-monthly-v2",
  BusinessPlusYearlyV1 = "business-plus-yearly-v1",
  BusinessPlusYearlyV2 = "business-plus-yearly-v2",
}

enum StripePlanKind {
  Business = "Business",
  BusinessPlus = "Business Plus",
  Free = "Free",
  Pro = "Pro",
}

export { StripePlanExternalId, StripePlanKind };
