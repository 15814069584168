/**
 * A simple, select field that allows the current admin user to select a language.
 *
 * Currently used by the InviteUserToFieldwire modal
 */

import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";

import { Language } from "../../../types";

interface LanguageSelectInputProps {
  label: string;
  name: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value: string;
  required?: boolean;
}

interface LanguageOption {
  code: Language;
  name: string;
}

const languageOptions: LanguageOption[] = [
  {
    code: Language.EN,
    name: "English (US)",
  },
  {
    code: Language.EN_GB,
    name: "English (GB)",
  },
  {
    code: Language.ZH,
    name: "简体中文 (Chinese)",
  },
  {
    code: Language.FR,
    name: "Français (French)",
  },
  {
    code: Language.DE,
    name: "Deutsch (German)",
  },
  {
    code: Language.NL,
    name: "Nederlands (Dutch)",
  },
  {
    code: Language.IT,
    name: "Italiano (Italian)",
  },
  {
    code: Language.JA,
    name: "日本語 (Japanese)",
  },
  {
    code: Language.KO,
    name: "한국어 (Korean)",
  },
  {
    code: Language.NO,
    name: "Norsk (Norwegian)",
  },
  {
    code: Language.PL,
    name: "Polskie (Polish)",
  },
  {
    code: Language.PT_BR,
    name: "Português (Portuguese)",
  },
  {
    code: Language.RU,
    name: "Pусский (Russian)",
  },
  {
    code: Language.ES,
    name: "Español (Spanish)",
  },
  {
    code: Language.SV,
    name: "Svenska (Swedish)",
  },
  {
    code: Language.VI,
    name: "Tiếng Việt (Vietnamese)",
  },
  {
    code: Language.EL,
    name: "Ελληνικά (Greek)",
  },
  {
    code: Language.CS,
    name: "Čeština (Czech)",
  },
  {
    code: Language.HU,
    name: "Magyar (Hungarian)",
  },
];

function LanguageSelectInput(props: LanguageSelectInputProps) {
  return (
    <TextField
      label={props.label}
      name={props.name}
      onChange={props.onChange}
      required={props.required}
      value={props.value}
      select
    >
      {languageOptions.map((language) => {
        return (
          <MenuItem key={language.code} value={language.code}>
            {language.name}
          </MenuItem>
        );
      })}
    </TextField>
  );
}

export default LanguageSelectInput;
