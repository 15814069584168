import { Stripe } from "@stripe/stripe-js";
// Importing from @stripe/stripe-js/pure as opposed to @stripe/stripe-js allows
// us to defer loading of the Stripe.js script until loadStripe is first called.
import { loadStripe } from "@stripe/stripe-js/pure";

import {
  CheckoutableStripePlanId,
  CheckoutableStripePlanInfo,
  CheckoutableStripePlanInfoWithId,
  StripePlanExternalId,
  StripeSubscriptionKind,
} from "../types";
import { env } from "../utils";

const stripePublishableKey = env.stripe.fetch_publishable_key();

interface CheckoutableStripePlanInfosWithIdCache {
  checkoutableStripePlanInfosWithId?: CheckoutableStripePlanInfoWithId[];
}

interface StripePromiseCache {
  stripePromise?: Promise<Stripe | null>;
}

const checkoutableStripePlanInfosWithIdCache: CheckoutableStripePlanInfosWithIdCache =
  {};

const stripePromiseCache: StripePromiseCache = {};

// A mapping of a stripe plan's internal id to the information associated
// with it needed for manual checkout through the admin dashboard.
const checkoutableStripePlanIdToInfo: {
  [key in CheckoutableStripePlanId]: CheckoutableStripePlanInfo;
} = {
  [StripePlanExternalId.ProMonthlyV2]: {
    displayName: "pro-monthly-v2 - 25.0 (LEGACY)",
  },
  [StripePlanExternalId.BusinessMonthlyV2]: {
    displayName: "business-monthly-v2 - 40.0 (LEGACY)",
  },
  [StripePlanExternalId.ProMonthlyV3]: {
    displayName: "pro-monthly-v3 - 39.0 (LEGACY)",
  },
  [StripePlanExternalId.BusinessMonthlyV3]: {
    displayName: "business-monthly-v3 - 49.0 (LEGACY)",
  },
  [StripePlanExternalId.ProYearlyV2]: {
    displayName: "pro-yearly-v2 - 250.0 (LEGACY)",
  },
  [StripePlanExternalId.BusinessYearlyV2]: {
    displayName: "business-yearly-v2 - 400.0 (LEGACY)",
  },
  [StripePlanExternalId.ProYearlyV3]: {
    displayName: "pro-yearly-v3 - 348.0 (LEGACY)",
  },
  [StripePlanExternalId.BusinessYearlyV3]: {
    displayName: "business-yearly-v3 - 468.0 (LEGACY)",
  },
  [StripePlanExternalId.ProYearlyV4]: {
    displayName: "pro-yearly-v4 - 348.0 (LEGACY)",
  },
  [StripePlanExternalId.BusinessYearlyV4]: {
    displayName: "business-yearly-v4 - 468.0 (LEGACY)",
  },
  [StripePlanExternalId.ProMonthlyV4]: {
    displayName: "pro-monthly-v4 - 44.0 (LEGACY)",
  },
  [StripePlanExternalId.BusinessMonthlyV4]: {
    displayName: "business-monthly-v4 - 54.0 (LEGACY)",
  },
  [StripePlanExternalId.ProYearlyV5]: {
    displayName: "pro-yearly-v5 - 348.0 (LEGACY)",
  },
  [StripePlanExternalId.BusinessYearlyV5]: {
    displayName: "business-yearly-v5 - 468.0 (LEGACY)",
  },
  [StripePlanExternalId.BusinessMonthlyV5]: {
    displayName: "business-monthly-v5 - 64.0 (LEGACY)",
  },
  [StripePlanExternalId.BusinessYearlyV6]: {
    displayName: "business-yearly-v6 - 588.0 (LEGACY)",
  },
  [StripePlanExternalId.ProYearlyV6]: {
    displayName: "pro-yearly-v6 - 486.0 (LEGACY)",
  },
  [StripePlanExternalId.ProMonthlyV5]: {
    displayName: "pro-monthly-v5 - 54.0",
  },
  [StripePlanExternalId.BusinessMonthlyV6]: {
    displayName: "business-monthly-v6 - 74.0",
  },
  [StripePlanExternalId.BusinessPlusMonthlyV1]: {
    displayName: "business-plus-monthly-v1 - 89.0 (LEGACY)",
  },
  [StripePlanExternalId.BusinessYearlyV7]: {
    displayName: "business-yearly-v7 - 708.0",
  },
  [StripePlanExternalId.BusinessPlusYearlyV1]: {
    displayName: "business-plus-yearly-v1 - 888.0 (LEGACY)",
  },
  [StripePlanExternalId.ProYearlyV7]: {
    displayName: "pro-yearly-v7 - 468.0",
  },
  [StripePlanExternalId.BusinessPlusMonthlyV2]: {
    displayName: "business-plus-monthly-v2 - 94.0",
  },
  [StripePlanExternalId.BusinessPlusYearlyV2]: {
    displayName: "business-plus-yearly-v2 - 948.0",
  },
};

/**
 * Transform the `checkoutableStripePlanIdToInfo` map into a sorted list of
 * checkoutable stripe plan info objects (that also include their internal id!).
 */
function computeCheckoutableStripePlanInfosWithId() {
  const checkoutableStripePlanInfosWithId = Object.keys(
    checkoutableStripePlanIdToInfo
  ).map((key: string) => {
    // The `Object.keys` method always returns the keys as strings
    // so we need to cast them back into `CheckoutableStripePlanId`s.
    const id = key as CheckoutableStripePlanId;
    const checkoutableStripePlanInfo = checkoutableStripePlanIdToInfo[id];
    const checkoutableStripePlanInfoWithId = {
      ...checkoutableStripePlanInfo,
      id,
    };
    return checkoutableStripePlanInfoWithId;
  });

  checkoutableStripePlanInfosWithId.sort(
    (
      a: CheckoutableStripePlanInfoWithId,
      b: CheckoutableStripePlanInfoWithId
    ) => {
      const aLegacy = isLegacy(a.id);
      const bLegacy = isLegacy(b.id);
      if (!aLegacy && bLegacy) {
        return 1;
      } else if (aLegacy && !bLegacy) {
        return -1;
      } else {
        return 0;
      }
    }
  );

  return checkoutableStripePlanInfosWithId;
}

/**
 * Returns a sorted list of checkoutable stripe plan infos from cache. Otherwise
 * computes the list, caches it, and then returns the computed list.
 */
function getCheckoutableStripePlanInfosWithId() {
  if (
    checkoutableStripePlanInfosWithIdCache.checkoutableStripePlanInfosWithId
  ) {
    return checkoutableStripePlanInfosWithIdCache.checkoutableStripePlanInfosWithId;
  }

  checkoutableStripePlanInfosWithIdCache.checkoutableStripePlanInfosWithId =
    computeCheckoutableStripePlanInfosWithId();

  return checkoutableStripePlanInfosWithIdCache.checkoutableStripePlanInfosWithId;
}

/**
 * Returns a Stripe promise from cache. Otherwise loads Stripe, caches it, and returns promise.
 */
function getStripePromise() {
  if (stripePromiseCache.stripePromise) {
    return stripePromiseCache.stripePromise;
  }

  stripePromiseCache.stripePromise = loadStripe(stripePublishableKey);

  return stripePromiseCache.stripePromise;
}

/**
 * Check if a checkoutable stripe plan is legacy.
 */
function isLegacy(id: CheckoutableStripePlanId) {
  return checkoutableStripePlanIdToInfo[id].displayName.includes("LEGACY");
}

/**
 * Check if a checkoutable stripe plan is yearly.
 */
function isYearly(id: CheckoutableStripePlanId) {
  return checkoutableStripePlanIdToInfo[id].displayName.includes("yearly");
}

/**
 * Check if a checkoutable stripe plan corresponds to
 * a stripe subscription of kind business or pro.
 */
function kind(id: CheckoutableStripePlanId) {
  return id.includes("business")
    ? StripeSubscriptionKind.Business
    : StripeSubscriptionKind.Pro;
}

export {
  getCheckoutableStripePlanInfosWithId,
  getStripePromise,
  isLegacy,
  isYearly,
  kind,
};
