import { Action, DataKind, Permissions } from "../../types";

const ACCOUNT_MANAGER_PERMISSIONS: Permissions = {
  [DataKind.Account]: [Action.Create, Action.Read, Action.Update],
  [DataKind.ActiveUserCount]: [
    Action.Read,
    // Note that an admin user will never actually update active user count
    // data. This is simply a representation used to differentiate between
    // admin users who can and can't view the financial data portion of the
    // active users counts table. This mirrors our BE which makes such a
    // distinction.
    Action.Update,
  ],
  [DataKind.CanceledEnterpriseOwner]: [Action.Read],
  [DataKind.CanceledInAppPurchaseUser]: [Action.Read],
  [DataKind.CanceledStripeOwner]: [Action.Read],
  [DataKind.Charge]: [Action.Read],
  [DataKind.Company]: [
    Action.Create,
    Action.Read,
    Action.Update,
    Action.Delete,
  ],
  [DataKind.EnterprisePlan]: [Action.Read],
  [DataKind.EnterprisePlanInvoice]: [Action.Read],
  [DataKind.PayingEnterpriseOwner]: [Action.Read],
  [DataKind.PayingInAppPurchaseUser]: [Action.Read],
  [DataKind.PayingStripeOwner]: [Action.Read],
  [DataKind.Project]: [
    Action.Create,
    Action.Read,
    Action.Update,
    Action.Delete,
  ],
  [DataKind.User]: [Action.Create, Action.Read, Action.Update],
};

const CONFIG_VARS_MANAGER_PERMISSIONS: Permissions = {
  [DataKind.ConfigVariable]: [
    Action.Create,
    Action.Read,
    Action.Update,
    Action.Delete,
  ],
};

const DEBUG_INFO_VIEWER_PERMISSIONS: Permissions = {
  [DataKind.BoxToken]: [Action.Update],
  [DataKind.ConsolidatedProject]: [Action.Read, Action.Update],
  [DataKind.ConsolidatedProjectUsers]: [Action.Read, Action.Update],
  [DataKind.DropboxToken]: [Action.Update],
  [DataKind.MicrosoftToken]: [Action.Update],
  [DataKind.Sidekiq]: [Action.Read],
  [DataKind.SyncError]: [Action.Read],
};

const DEFAULT_PERMISSIONS: Permissions = {
  [DataKind.Account]: [Action.Read],
  [DataKind.AccountUserCount]: [Action.Read],
  [DataKind.AdminUser]: [Action.Read],
  [DataKind.AdminRoleMembership]: [Action.Read],
  [DataKind.BoxToken]: [Action.Read],
  [DataKind.Company]: [Action.Read],
  [DataKind.DemoProjectTemplate]: [Action.Read],
  [DataKind.DemoProjectUser]: [Action.Read],
  [DataKind.DropboxToken]: [Action.Read],
  [DataKind.FeatureFlag]: [Action.Read],
  [DataKind.IosReleaseStatus]: [Action.Read],
  [DataKind.MicrosoftToken]: [Action.Read],
  [DataKind.Project]: [Action.Read],
  [DataKind.ProjectsUser]: [Action.Read],
  [DataKind.SystemStatus]: [Action.Read],
  [DataKind.User]: [Action.Read],
};

const DEMO_TEMPLATES_MANAGER_PERMISSIONS: Permissions = {
  [DataKind.DemoProjectTemplate]: [
    Action.Create,
    Action.Read,
    Action.Update,
    Action.Delete,
  ],
  [DataKind.DemoProjectUser]: [
    Action.Create,
    Action.Read,
    Action.Update,
    Action.Delete,
  ],
};

const FINANCE_OPERATOR_PERMISSIONS: Permissions = {
  [DataKind.Account]: [Action.Create, Action.Read, Action.Update],
  [DataKind.ActiveUserCount]: [
    Action.Read,
    // Note that an admin user will never actually update active user count
    // data. This is simply a representation used to differentiate between
    // admin users who can and can't view the financial data portion of the
    // active users counts table. This mirrors our BE which makes such a
    // distinction.
    Action.Update,
  ],
  [DataKind.AdminUser]: [
    Action.Read,
    // These permissions are to let our IT team invite, manage & remove
    // admin users as required (ex: onboarding, offboarding new Fieldies,
    // resetting password etc.)
    Action.Create,
    Action.Update,
    Action.Delete,
  ],
  [DataKind.AdminRoleMembership]: [
    Action.Read,
    // These permissions are to let our IT team manage the roles of our
    // admin users. Even though these look like they would allow our IT
    // team to elevate themselves or others to `Super` role, our BE would
    // still fail those operations with a helpful message. We aren't
    // enforcing them here to keep the permission model simple
    Action.Create,
    Action.Delete,
  ],
  [DataKind.CanceledEnterpriseOwner]: [Action.Read],
  [DataKind.CanceledInAppPurchaseUser]: [Action.Read],
  [DataKind.CanceledStripeOwner]: [Action.Read],
  [DataKind.Charge]: [Action.Read, Action.Update],
  [DataKind.Company]: [
    Action.Create,
    Action.Read,
    Action.Update,
    Action.Delete,
  ],
  [DataKind.EnterprisePlan]: [Action.Create, Action.Read, Action.Update],
  [DataKind.EnterprisePlanInvoice]: [Action.Create, Action.Read, Action.Update],
  [DataKind.PayingEnterpriseOwner]: [Action.Read],
  [DataKind.PayingInAppPurchaseUser]: [Action.Read],
  [DataKind.PayingStripeOwner]: [Action.Read],
  [DataKind.Project]: [
    Action.Create,
    Action.Read,
    Action.Update,
    Action.Delete,
  ],
  [DataKind.StripeSubscription]: [Action.Update],
  [DataKind.User]: [Action.Create, Action.Read, Action.Update],
};

const FINANCE_VIEWER_PERMISSIONS: Permissions = {
  [DataKind.ActiveUserCount]: [Action.Read],
  [DataKind.CanceledEnterpriseOwner]: [Action.Read],
  [DataKind.CanceledInAppPurchaseUser]: [Action.Read],
  [DataKind.CanceledStripeOwner]: [Action.Read],
  [DataKind.Charge]: [Action.Read],
  [DataKind.EnterprisePlan]: [Action.Read],
  [DataKind.EnterprisePlanInvoice]: [Action.Read],
  [DataKind.PayingEnterpriseOwner]: [Action.Read],
  [DataKind.PayingInAppPurchaseUser]: [Action.Read],
  [DataKind.PayingStripeOwner]: [Action.Read],
};

const JOBS_MANAGER_PERMISSIONS: Permissions = {
  [DataKind.JobInfo]: [
    Action.Create,
    Action.Read,
    Action.Update,
    Action.Delete,
  ],
  [DataKind.Sidekiq]: [Action.Read],
};

const MARKETER_PERMISSIONS: Permissions = {
  [DataKind.ActiveUserCount]: [Action.Read],
};

const SSO_INFO_MANAGER_PERMISSIONS: Permissions = {
  [DataKind.SsoInfo]: [
    Action.Create,
    Action.Read,
    Action.Update,
    Action.Delete,
  ],
};

const SUPPORT_PERMISSIONS: Permissions = {
  [DataKind.Account]: [Action.Create, Action.Read, Action.Update],
  [DataKind.CanceledEnterpriseOwner]: [Action.Read],
  [DataKind.CanceledInAppPurchaseUser]: [Action.Read],
  [DataKind.CanceledStripeOwner]: [Action.Read],
  [DataKind.Charge]: [Action.Read],
  [DataKind.EnterprisePlan]: [Action.Create, Action.Read, Action.Update],
  [DataKind.PayingEnterpriseOwner]: [Action.Read],
  [DataKind.PayingInAppPurchaseUser]: [Action.Read],
  [DataKind.PayingStripeOwner]: [Action.Read],
  [DataKind.Project]: [
    Action.Create,
    Action.Read,
    Action.Update,
    Action.Delete,
  ],
  [DataKind.SsoInfo]: [Action.Read],
  [DataKind.StripeSubscription]: [Action.Update],
  [DataKind.User]: [Action.Create, Action.Read, Action.Update],
};

export {
  ACCOUNT_MANAGER_PERMISSIONS,
  CONFIG_VARS_MANAGER_PERMISSIONS,
  DEBUG_INFO_VIEWER_PERMISSIONS,
  DEFAULT_PERMISSIONS,
  DEMO_TEMPLATES_MANAGER_PERMISSIONS,
  FINANCE_OPERATOR_PERMISSIONS,
  FINANCE_VIEWER_PERMISSIONS,
  JOBS_MANAGER_PERMISSIONS,
  MARKETER_PERMISSIONS,
  SSO_INFO_MANAGER_PERMISSIONS,
  SUPPORT_PERMISSIONS,
};
