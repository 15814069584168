import { NumberField, TextField, TextInput } from "react-admin";

import { DataKind, Resource } from "../../types";
import { CustomDateTimeField, CustomList } from "../custom";

// "By default, <List> does not refresh the data as soon as the user enters
// data in the filter form. Instead, it waits for half a second of user
// inactivity (via `lodash.debounce`) before calling the dataProvider
// on filter change. This is to prevent repeated calls to the API."
//
// Source: https://marmelab.com/react-admin/List.html#debounce
const canceledEnterpriseOwnerFilters = [
  <TextInput label="Email" source="email_like" alwaysOn />,
];

function CanceledEnterpriseOwnerList() {
  return (
    <CustomList
      checkPermissionFor={DataKind.CanceledEnterpriseOwner}
      displayCreate={false}
      filters={canceledEnterpriseOwnerFilters}
      customRowClickConfig={{
        reference: Resource.EnterprisePlan,
        source: "id",
      }}
    >
      <TextField label="User Id" source="user_id" sortable={false} />
      <TextField label="Account Id" source="account_id" sortable={false} />
      <TextField label="Email" source="email" sortable={false} />
      <TextField label="Company" source="company" sortable={false} />
      <TextField label="Name" source="name" sortable={false} />
      <NumberField label="Price" source="price" sortable={false} />
      <NumberField
        label="Max User Count"
        source="max_user_count"
        sortable={false}
      />
      <CustomDateTimeField
        label="Start At"
        source="start_at"
        sortable={false}
      />
      <CustomDateTimeField label="End At" source="end_at" sortable={false} />
      <TextField label="Category" source="category" sortable={false} />
      {/* MISSING: Last User Count */}
    </CustomList>
  );
}

export default CanceledEnterpriseOwnerList;
