/**
 * A show table for projects associated with a sync error or a
 * sync token (box token, dropbox token, or a microsoft token).
 *
 * Based on the following table from Admin V1:
 *
 * https://github.com/Fieldwire/fieldwire_api/blob/9b6530a077e865c51adca7e983e984db6967ca8c/app/views/active_admin/_project_table.html.arb
 */

import { BooleanField, NumberField, TextField } from "react-admin";

import { Region, Resource } from "../../../types";
import { resourceUtils } from "../../../utils";
import {
  CustomDatagrid,
  CustomDateTimeField,
  CustomReferenceField,
  CustomReferenceManyField,
  LabelWithInfoTooltip,
  ShowTable,
} from "..";

interface ProjectsForSyncProps {
  region: Region;
  // The resource that the projects are associated with (ex: box token).
  target: Resource;
}

function ProjectsForSync(props: ProjectsForSyncProps) {
  return (
    <ShowTable rowFlexDirection="column" title="Projects">
      <CustomReferenceManyField
        label={false}
        defaultSort={{ field: "created_at", order: "DESC" }}
        displayPagination={true}
        reference={resourceUtils.projectResourceFor(props.region)}
        target={props.target}
      >
        <CustomDatagrid>
          <CustomReferenceField
            label="Plan Info"
            reference={Resource.Account}
            source="account_id"
            sortable={false}
          >
            <TextField source="plan_info" />
          </CustomReferenceField>
          <CustomReferenceField
            label="Owner Email"
            reference={Resource.User}
            source="owner_user_id"
            sortable={false}
          >
            <TextField source="email" />
          </CustomReferenceField>
          <TextField label="Name" source="name" />
          <CustomDateTimeField label="Created At" source="created_at" />
          {/*
            This field is technically sortable on the BE, but the
            serializer uses a different name from what the BE expects.
          */}
          <NumberField
            label="Task Count"
            source="tasks_sequence_counter"
            sortable={false}
          />
          <BooleanField
            label={
              <LabelWithInfoTooltip
                labelText="Is Sample Project"
                tooltipText="Sample projects are not included in analytics."
              />
            }
            source="is_sample_project"
            sortable={false}
          />
          <CustomDateTimeField
            label="Archived At"
            source="archived_at"
            sortable={false}
          />
          <CustomDateTimeField label="Deleted At" source="deleted_at" />
        </CustomDatagrid>
      </CustomReferenceManyField>
    </ShowTable>
  );
}

export default ProjectsForSync;
