import { NullableBooleanInput, SelectInput, TextInput } from "react-admin";

import { AccountRole, DataKind } from "../../types";
import { componentUtils } from "../component_utils";
import { CustomEdit, CustomNumberInput } from "../custom";

const accountRoleChoices =
  componentUtils.generateCapitalCaseSelectChoicesFromEnum(AccountRole);

function UserEdit() {
  return (
    <CustomEdit checkPermissionFor={DataKind.User}>
      <NullableBooleanInput label="Is Approved" source="is_approved" required />
      <TextInput label="First Name" source="first_name" required />
      <TextInput label="Last Name" source="last_name" required />
      <TextInput label="Company" source="company" />
      <TextInput label="Job Title" source="job_title" />
      <TextInput label="Phone Number" source="phone_number" />
      <TextInput label="Email" source="email" disabled />
      <CustomNumberInput label="Account ID" source="account_id" required />
      <SelectInput
        label="Account Role"
        source="account_role"
        choices={accountRoleChoices}
        required
      />
    </CustomEdit>
  );
}

export default UserEdit;
